import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import AddPhoto from './admin/AddPhoto';
import AdminPhotos from './admin/AdminPhotos';
import Home from './pages/Home';
import Photos from './pages/Photos';
import Navbar from './components/Navbar';
import { ThemeProvider } from '@mui/system';
import { theme } from './styles/Theme';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Partners from './pages/Partners';
import Maintenance from './pages/Maintenance';
import Footer from './components/Footer';
import Construction from './admin/Construction';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        {window.location.pathname !== '/' && <Navbar />}
        <Routes>
          <Route path="/" element={<Construction />} />
          {/* Main Routes */}
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/photos" element={<Photos />} />
          <Route path='/services' element={<Services />} />
          <Route path='/partners' element={<Partners />} />
          <Route path='/maintenance' element={<Maintenance />} />
          <Route path="/contact" element={<Contact />} />
          {/* Admin Routes */}
          <Route path="/admin/addphoto" element={<AddPhoto />} />
          <Route path="/admin/photos" element={<AdminPhotos />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
