import React from 'react';
import construction from '../images/Construction.png';

function Construction(props) {
  return (
    <div style={{ textAlign: 'center' }}>
      <img src={construction} style={{ height: '40%', width: '40%', margin: 'auto' }} alt="Under Construction" />
    </div>
  )
}

export default Construction;