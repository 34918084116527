import React, { useEffect } from 'react';
import axios from 'axios';
import { ImageList, ImageListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Toast from '../components/Toast';

function Photos() {
  const [images, setImages] = React.useState([]);
  const [showToast, setShowToast] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [variant, setVariant] = React.useState("");

  useEffect(() => {
    getImages();
  }, [])

  const getImages = () => {
    axios({
      method: "GET",
      url: "https://lrautomotivedetailing.com/lucent-reflections-services/api/admin/media/all"
    }).then(function successCallBack(response) {
      setImages(response.data);
      if (response.data.length === 0) {
        setShowToast(true);
        setMsg("Images request succeeded but 0 images found");
        setVariant("warning");
      }
    }).catch((e) => {
      setVariant("error")
      setShowToast(true);
      setMsg("Error: " + e);
    })
  }

  const StyledDiv = styled('div')(
    ({ theme }) => ({
      color: theme.palette.body.color,
    })
  )

  const StyledHeading = styled(Typography)(
    ({ theme }) => ({
      color: theme.palette.body.white,
      fontSize: '2.5em',
      marginLeft: '2%'
    })
  );

  const onClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
    setMsg("");
    setVariant("");
  }

  return (
    <StyledDiv>
      <Toast
        open={showToast}
        message={msg}
        variant={variant}
        onClose={onClose}
      />
      <StyledHeading variant='h1'>Photos of previous jobs</StyledHeading>
      <ImageList>
        {images && images.map((i) => {
          return (
            <ImageListItem key={i.id} style={{ margin: 20 }}>
              <img height={"50%"} width={"50%"} alt="sample" src={`data:image /png; base64, ${i.image}`} />
              {i.description &&
                <Typography variant='subtitle2'>{i.description}</Typography>
              }
            </ImageListItem>
          )
        })}
      </ImageList>
    </StyledDiv>
  )
}

export default Photos;