import React, { useEffect } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Toast from '../components/Toast';

const StyledDiv = styled('div')(
  ({ theme }) => ({
    color: theme.palette.body.color
  })
)

function AdminPhotos() {
  const [images, setImages] = React.useState([]);
  const [showToast, setShowToast] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [variant, setVariant] = React.useState("");

  useEffect(() => {
    getImages();
  }, [])

  const getImages = () => {
    axios({
      method: "GET",
      url: "https://lrautomotivedetailing.com/lucent-reflections-services/api/admin/media/all"
    }).then(function successCallBack(response) {
      setImages(response.data);
      if (response.data.length === 0) {
        setShowToast(true);
        setMsg("Images request succeeded but 0 images found");
        setVariant("warning");
      }
    }).catch((e) => {
      setVariant("error")
      setShowToast(true);
      setMsg("Error: " + e);
    })
  }

  const handleDelete = (id) => {
    axios({
      method: "DELETE",
      url: "https://lrautomotivedetailing.com/lucent-reflections-services/api/admin/media/" + id
    }).then(function successCallBack(response) {
      setImages(response.data);
      if (response.data.length === 0) {
        setShowToast(true);
        setMsg("Image successfully deleted. No images remain in the database");
        setVariant("warning");
      } else {
        setShowToast(true);
        setMsg("Image successfully deleted");
        setVariant("success");
      }
    }).catch((e) => {
      setVariant("error")
      setShowToast(true);
      setMsg(e.toString());
    })
  }

  const onClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
    setMsg("");
    setVariant("");
  }

  return (
    <StyledDiv>
      <Toast
        open={showToast}
        message={msg}
        variant={variant}
        onClose={onClose}
      />
      <h1>View Photos (Count: {images.length})</h1>
      {images && images.map((i) => {
        return (
          <div key={i.id} style={{ marginBottom: 30 }}>
            <img height={"75%"} width={"75%"} alt="sample" src={`data:image /png; base64, ${i.image}`} />
            <p>Title: <span>{i.title}</span></p>
            {i.description &&
              <p>Description: <span>{i.description}</span></p>
            }
            {i.size &&
              <p>File Size: {Math.floor(i.size / 1000) + " KB"}</p>
            }
            {i.lastModifiedDate &&
              <p>Last Modified Date: {i.lastModifiedDate}</p>
            }
            {i.type &&
              <p>File Type: {i.type}</p>
            }
            <Button variant="contained" onClick={() => handleDelete(i.id)}>Delete</Button>
          </div>
        )
      })}
    </StyledDiv>
  )
}

export default AdminPhotos;