import { Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const StyledHeading = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.body.white,
    fontSize: '2.5em',
    marginLeft: '2%'
  })
);

function Home(props) {
  return (
    <div>
      <StyledHeading variant="h1">Lucent Reflections Auto Detailing</StyledHeading>
    </div>
  )
}

export default Home;