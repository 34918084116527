import React from 'react';
import axios from 'axios';
import { Button, Input, InputLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Toast from '../components/Toast';
import Dropzone from 'react-dropzone';

const StyledInput = styled(Input)(
  ({ theme }) => ({
    color: theme.palette.body.color,
    width: '100%',
    textAlign: 'center'
  })
)

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => ({
    color: theme.palette.body.color,
    marginTop: '2%'
  })
)

const StyledDiv = styled('div')(
  ({ theme }) => ({
    color: theme.palette.body.color,
    textAlign: 'center'
  })
)

const StyledButton = styled(Button)(
  ({ theme }) => ({
    margin: '2%'
  })
)

const StyledButtonContainer = styled('div')(
  ({ theme }) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  })
)

function AddPhoto() {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState("");
  const [showToast, setShowToast] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [variant, setVariant] = React.useState("");

  const handleChange = (value) => {
    setTitle(value);
  }

  const changeDescription = (value) => {
    setDescription(value);
  }

  // On file select (from the pop up)
  const onFileChange = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      acceptedFiles[0].url = URL.createObjectURL(acceptedFiles[0]);
      setSelectedFile(acceptedFiles[0]);
    } else {
      setMsg("Only one image can be uploaded at a time");
      setVariant("warning");
      setShowToast(true);
    }

    // Update the state
    // setSelectedFile(event.target.files[0]);
    // this.setState({ selectedFile: event.target.files[0] });

  };

  // On file upload (click the upload button)
  const onFileUpload = () => {

    // Create an object of formData
    const formData = new FormData();

    formData.append(
      "image",
      selectedFile
    );
    formData.append(
      "description",
      description
    );
    formData.append(
      "title",
      title ? title : selectedFile.name
    );
    formData.append(
      "lastModifiedDate",
      selectedFile.lastModifiedDate
    );

    // Details of the uploaded file
    // console.log(selectedFile);

    // Request made to the backend api
    // Send formData object
    axios({
      method: "POST",
      url: "https://lrautomotivedetailing.com/lucent-reflections-services/api/admin/media/add",
      data: formData
    }).then((response) => {
      setMsg("Successfully added image: " + selectedFile.name);
      setVariant("success");
      setShowToast(true);
      setSelectedFile(null);
      setTitle(null);
      setDescription("");
    }).catch((e) => {
      setVariant("error");
      setMsg(e.toString());
      setShowToast(true);
    });
  };
  // File content to be displayed after
  // file upload is complete
  const fileData = () => {

    if (selectedFile) {

      return (
        <div id="file-details-container" style={{ marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
          <img height={"50%"} width={"50%"} alt="sample" src={selectedFile.url}
          />
          <h2 id="file-details-header">File Details:</h2>

          <StyledInputLabel id="file-name-label" required>File Name</StyledInputLabel>
          <StyledInput id="file-name-input" autoCorrect='true' spellCheck value={title ? title : selectedFile.name} onChange={(value) => handleChange(value.target.value)} />

          <StyledInputLabel id="description-label" required>Description</StyledInputLabel>
          <StyledInput id="description-input" required autoCorrect='true' spellCheck value={description} onChange={(value) => changeDescription(value.target.value)} />

          <StyledInputLabel id="file-type-label">File Type:</StyledInputLabel>
          <Typography id="file-type-text" variant='subtitle1'>{selectedFile.type}</Typography>

          <StyledInputLabel id="file-size-label">File Size:</StyledInputLabel>
          <Typography id="file-size-text" variant='subtitle1'>{Math.floor(selectedFile.size / 1000) + " KB"}</Typography>

          {/* <StyledInputLabel id="last-modified-label">Last Modified:</StyledInputLabel>
          <Typography id="last-modified-text" variant='subtitle1'>{selectedFile.lastModifiedDate.toISOString()}</Typography> */}

          <StyledButtonContainer>
            <StyledButton id="upload-image-submit-button" variant="contained" onClick={onFileUpload} disabled={!selectedFile || !description || description === undefined || description === ""}>
              Upload!
            </StyledButton>
            <StyledButton id="upload-image-reset-button" variant="contained" onClick={() => { setTitle(''); setSelectedFile(null) }}>Reset</StyledButton>

          </StyledButtonContainer>

        </div>
      );
    } else {
      return (
        <div id="file-details-placeholder-container">
          <br />
          <h4 id="file-details-placeholder-text">Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  const onClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
    setMsg("");
    setTimeout(() => {
      setVariant("");
    }, 2000)
  }

  return (
    <StyledDiv id="page-container">
      <Toast
        id="user-notification-toast"
        open={showToast}
        message={msg}
        variant={variant}
        onClose={onClose}
      />
      <h1 id="upload-image-header">
        Upload Images
      </h1>
      <div id="upload-image-container">
        {!selectedFile &&
          <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)} maxFiles={1} >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>}
        {/* <StyledUploadInput id="upload-image-input" type="file" accept="image/*" onChange={onFileChange} /> */}
      </div>
      {fileData()}
    </StyledDiv>
  );
}
export default AddPhoto;