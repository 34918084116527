import React from 'react';

function Contact(props) {
  return (
    <div>
      <p style={{ color: '#fff' }}>email: info@lrautodetailing.com</p>
    </div>
  )
}

export default Contact;