import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    type: 'light',
    common: {
      gray: '#9e9e9e'
    },
    primary: {
      main: '#000'
    },
    secondary: {
      main: '#d32f2f'
    },
    body: {
      color: '#fff',
      white: '#ffffff',
      backgroundColor: '#000',
      btn: '#f44336',
      hov: '#c62828'
    },
  },
  breakpoints: {
    values: {
      xs: 600,
      sm: 967,
      md: 1100
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#000'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        '&.Mui-disabled': {
          color: '#fff'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: 'black'
        }
      }
    }
  },
  typography: {
    tab: {
      fontFamily: "Roboto, Helvetica, Arial, serif",
      textTransform: 'none',
      fontWeight: '700',
      fontSize: '1rem',
    },
    btn: {
      fontFamily: "Roboto, Helvetica, Arial, serif",
      fontSize: '1rem',
      textTransform: 'none',
      color: '#fff'
    }
  },
  button: {
    backgroundColor: '#23fd4a',
    contained: {
      backgroundColor: '#424242'
    }
  }
});