import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';


const StyledHeading = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.body.white,
    fontSize: '2.5em',
    marginLeft: '2%'
  })
);

function About(props) {

  return (
    <div>
      <StyledHeading variant="h1">About our team</StyledHeading>
    </div>
  )
}

export default About;