import React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { styled } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} followCursor />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  },
}));

const ToolbarWrapper = styled(Toolbar)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    justifyContent: 'center'
  })
);

const ToolbarMargin = styled('div')(
  ({ theme }) => ({
    ...theme.mixins.toolbar,
  })
);

const StyledContactInfo = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.body.white,
    paddingTop: '2%',
    display: 'inline',
    marginLeft: '2%',
    marginRight: '2%'
  })
)

const StyledLink = styled('a')(
  ({ theme }) => ({
    color: theme.palette.body.white,
  })
)

const StyledSpan = styled('span')(
  ({ theme }) => ({
    width: '120px'
  })
)

function Footer(props) {

  return (
    <div>
      <ToolbarMargin />
      <AppBar>
        <ToolbarWrapper>
          <StyledSpan>
            <StyledContactInfo
              variant={'subtitle1'}>
              <StyledLink href='mailto:lucentreflections@gmail.com' target="_blank">
                <LightTooltip title="Email Lucent Reflections">
                  <EmailIcon />
                </LightTooltip>
              </StyledLink>
            </StyledContactInfo>
            <StyledContactInfo variant={'subtitle1'}>
              <StyledLink href="tel:+14843581130">
                <LightTooltip title="Call Lucent Reflections">
                  <PhoneIcon />
                </LightTooltip>
              </StyledLink>
            </StyledContactInfo>
            <StyledContactInfo variant={'subtitle1'}>
              <StyledLink href="https://www.facebook.com/lucentreflections" target="_blank">
                <LightTooltip title="Lucent Reflections Facebook">
                  <FacebookIcon />
                </LightTooltip>
              </StyledLink>
            </StyledContactInfo>
            <StyledContactInfo>
              <StyledLink href="https://www.instagram.com/lucent_reflections/?hl=en" target="_blank">
                <LightTooltip title="Lucent Reflections Instagram">
                  <InstagramIcon />
                </LightTooltip>
              </StyledLink>
            </StyledContactInfo>
          </StyledSpan>
        </ToolbarWrapper>
      </AppBar>
    </div>
  )
}

export default Footer;