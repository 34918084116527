import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/LucentReflections_Icon.png';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Tabs, Tab } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

const ToolbarWrapper = styled(Toolbar)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary
  })
);

const ToolbarMargin = styled('div')(
  ({ theme }) => ({
    ...theme.mixins.toolbar,
  })
);

const TabsWrapper = styled(Tabs)(
  ({ theme }) => ({
    marginLeft: 'auto'
  })
)

const TabWrapper = styled(Tab)(
  ({ theme }) => ({
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: '25px',
    color: theme.palette.body.white,
    '&:hover': {
      color: theme.palette.body.hov
    },
    '&.Mui-selected': {
      color: theme.palette.common.gray
    }
  })
);

const LinkWrapper = styled(Link)(
  ({ theme }) => ({
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '1rem',
    color: theme.palette.body.white
  })
);

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 9 : 0,
  });
}

function Navbar(props) {
  const [value, setValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  // const [editTitle, setEditTitle] = React.useState(false);
  // const [title, setTitle] = React.useState(props.title);
  // const [origTitle, setOrigTitle] = React.useState(props.title);
  // const [admin, setAdmin] = React.useState(false);

  // const handleTitleChange = (value) => {
  //   setTitle(value.target.value);
  // }

  // const onSaveTitle = () => {
  //   axios({
  //     method: "POST",
  //     url: "http://localhost:8080/redwolfsoftware-services/api/admin/update/title",
  //     data: [origTitle, title]
  //   }).then((response) => {
  //     setTitle(response.data.title);
  //     setOrigTitle(response.data.title);
  //   })
  // }

  // React.useEffect(() => {
  //   if (window.location.pathname == "/admin/home" || window.location.pathname == "/admin/photos" || window.location.pathname == "/admin/addPhoto" || window.location.pathname == "/admin/update") {
  //     setAdmin(true);
  //   } else {
  //     setAdmin(false);
  //   }
  // })

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      document.title = 'Lucent Reflections Automotive Detailing - Home'
    } else if (newValue === 1) {
      document.title = 'Lucent Reflections Automotive Detailing - About Us'
    } else if (newValue === 2) {
      document.title = 'Lucent Reflections Automotive Detailing - Portfolio'
    } else if (newValue === 3) {
      document.title = 'Lucent Reflections Automotive Detailing - Services'
    } else if (newValue === 4) {
      document.title = "Lucent Reflections Automotive Detailing - Partners"
    } else if (newValue === 5) {
      document.title = "Lucent Reflections Automotive Detailing - Maintenance"
    }
    // else if (newValue === 6) {
    //   document.title = 'Lucent Reflections - Contact'
    // }
    setValue(newValue);
  };

  let handleClick = () => {
    window.location = "/home";
    document.title = 'Lucent Reflections Automotive Detailing - Home'
  };

  useEffect(() => {
    if (window.location.pathname === '/home' && value !== 0) {
      document.title = 'Lucent Reflections Automotive Detailing - Home';
      setValue(0);
    } else if (window.location.pathname === '/about' && value !== 1) {
      document.title = 'Lucent Reflections Automotive Detailing - About Us';
      setValue(1);
    } else if (window.location.pathname === '/photos' && value !== 2) {
      document.title = 'Lucent Reflections Automotive Detailing - Portfolio';
      setValue(2);
    } else if (window.location.pathname === '/services' && value !== 3) {
      document.title = 'Lucent Reflections Automotive Detailing - Services';
      setValue(3);
    } else if (window.location.pathname === '/partners' && value !== 4) {
      document.title = 'Lucent Reflections Automotive Detailing - Partners';
      setValue(4);
    } else if (window.location.pathname === '/maintenance' && value !== 5) {
      document.title = 'Lucent Reflections Automotive Detailing - Maintenance';
      setValue(5);
    }
    // else if (window.location.pathname === '/contact' && value !== 6) {
    //   document.title = 'Lucent Reflections - Contact'
    //   setValue(6);
    // }
  }, [value])

  const theme = useTheme();
  const sMatches = useMediaQuery(theme.breakpoints.down('md'))

  const tabs = (
    <TabsWrapper value={value} onChange={handleChange} indicatorColor='primary'>
      <TabWrapper component={Link} to="/home" label={"Home"} />
      <TabWrapper component={Link} to="/about" label={"About Us"} />
      <TabWrapper component={Link} to="/photos" label={"Portfolio"} />
      <TabWrapper component={Link} to='/services' label={'Services'} />
      <TabWrapper component={Link} to='/partners' label={'Our Partners'} />
      <TabWrapper component={Link} to='/maintenance' label={'Maintenance/After Care'} />
      {/* <TabWrapper component={Link} to="/contact" label={"Contact Us"} /> */}
    </TabsWrapper>
  )
  const drawer = (
    <React.Fragment>
      <Button style={{ color: '#fff' }} onClick={() => setDrawerOpen(true)} startIcon={<MenuIcon />}></Button>
      <SwipeableDrawer
        onOpen={() => null} open={drawerOpen} anchor={'left'} onClose={() => setDrawerOpen(false)}>
        <List>
          <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/home'>Home</LinkWrapper>
          </ListItem>
          <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/about'>About Us</LinkWrapper>
          </ListItem>
          <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/photos'>Portfolio
            </LinkWrapper>
          </ListItem>
          <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/services'>Services</LinkWrapper>
          </ListItem>
          <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/partners'>Partners</LinkWrapper>
          </ListItem>
          <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/maintenance'>Maintenance/After Care</LinkWrapper>
          </ListItem>
          {/* <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/contact'
            >
              Contact Us
            </LinkWrapper>
          </ListItem> */}
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  )

  return (
    <div>
      <ElevationScroll>
        <AppBar>
          <ToolbarWrapper>
            {/* {editTitle &&
              <React.Fragment>
                <Input value={title} onChange={(value) => handleTitleChange(value)} />
                <IconButton onClick={() => { onSaveTitle(); console.log("saved new title"); setEditTitle(false) }}><SaveIcon /></IconButton>
              </React.Fragment>
            } */}
            <Link
              to={'/home'}
              onClick={() => {
                setValue(0);
                document.title = 'Lucent Reflections Automotive Detailing - Home';
              }}
            // onClick={() => { setAdmin(false) }}
            >
              <img
                style={{ marginRight: '5px' }}
                height='40px'
                width='40px'
                src={logo}
                alt={'Logo'}
              />
            </Link>
            {/* {!editTitle && */}
            <React.Fragment>
              <Typography
                style={{ marginRight: '5px', color: '#c62828' }}
                onClick={handleClick}
                variant={sMatches ? 'h6' : 'h5'}>
                Lucent Reflections
              </Typography>
              {/* {admin && <IconButton
                  onClick={() => { setEditTitle(true) }}
                >
                  <EditIcon />
                </IconButton>} */}
            </React.Fragment>
            {/* } */}
            {sMatches ? drawer : tabs}
          </ToolbarWrapper>
        </AppBar>
      </ElevationScroll>
      <ToolbarMargin />
    </div>
  )
}

export default Navbar;
