import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const StyledDiv = styled('div')(
  ({ theme }) => ({
    color: theme.palette.body.color,
    marginLeft: '2%',
  })
)

const StyledHeading = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.body.white,
    fontSize: '2.5em',
  })
);

function Services(prop) {
  return (
    <StyledDiv>
      <StyledHeading variant='h1'>Services: </StyledHeading>
    </StyledDiv>
  )
}

export default Services;